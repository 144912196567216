import { useItemTags } from '../hooks/useItemTags';
import lock from '../img/icons/lock.svg';

export const Tags = ({ item, currentTier, loyaltySchemeVersion, showLocked, excludeChildTags, showLowerBirthdayTiers = false, wrapperClass, outOfStockOverride = false, outOfStockDisabled }) => {
    const { loyaltyTags, birthdayTags, uiTags, lockedBDayTags, lockedLoyaltyTags, hasTags } = useItemTags(item, currentTier, loyaltySchemeVersion, showLocked, excludeChildTags, showLowerBirthdayTiers);
    if (!hasTags && !item?.outOfStock && !outOfStockOverride) return null;
    return <div className={wrapperClass}>
        {
            (item?.outOfStock || outOfStockOverride) && !outOfStockDisabled ?
            <div className='ui-tag outofstock'>
                Out of stock
            </div>
            : null
        }
        {birthdayTags?.length > 0 &&
            birthdayTags.map(tag => (<div key={'birthdayTag' + tag} data-testid={`tag-birthday-${tag}`} className={`tag ui-tag ${tag.toLowerCase()}`}>Birthday&nbsp;Treat</div>))}
        {loyaltyTags?.length > 0 &&
            loyaltyTags.map(tag => (<div key={'loyaltyTags' + tag} data-testid={`tag-tier-reward-tag-${tag}`} className={`tag ui-tag ${tag.toLowerCase()}`}>{tag}&nbsp;Reward{item.id === '27' ? ' - Free Romana Upgrade' : ''}</div>))}
        {lockedBDayTags?.length > 0 &&
            lockedBDayTags.map(tag => (<div key={'lockedBDayTags' + tag} data-testid={`tag-birthday-${tag}-locked`} className={`tag ui-tag ${tag.toLowerCase()} locked`}> Birthday Treat <img alt="locked" src={lock} className="icon is-small" style={{width: 24}}/> </div>))}
        {lockedLoyaltyTags?.length > 0 &&
            lockedLoyaltyTags.map(tag => (<div key={'lockedLoyaltyTags' + tag} data-testid={`tag-tier-reward-tag-${tag}-locked`} className={`tag ui-tag ${tag.toLowerCase()} locked`}>{tag}&nbsp;Reward <img alt="locked" src={lock} className="icon is-small" style={{width: 24}}/> </div>))}
        {uiTags?.length > 0 &&
            uiTags.map(tag => (<div key={'uiTags' + tag} data-testid={`tag-ui-${tag}`} className={`tag ui-tag new`}>{tag.startsWith('#') ? tag.slice(1) : tag}</div>))}
    </div>
}