import { ApplicationResponse, ApplicationRequest } from '../types/ApplicationTypes';
import api from './api';

export const applicationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationState: builder.query<ApplicationResponse, ApplicationRequest>({
      query: ({ restaurantId, menuHash }) => `application?restaurantId=${restaurantId}&menuEtag=${menuHash}`,
      providesTags: (result) => result?.menu
        ? ['MenuState']
        : []
    }),
    getCustomerState: builder.query<ApplicationResponse, string | undefined>({
      query: (restaurantId) => restaurantId ? `application/getCustomerState?restaurantId=${restaurantId}` : 'application/getCustomerState'
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationStateQuery,
  useLazyGetApplicationStateQuery,
  useGetCustomerStateQuery,
  useLazyGetCustomerStateQuery
} = applicationApi;