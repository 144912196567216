/* eslint-disable @typescript-eslint/no-explicit-any */
import { filterAvailableBundles } from "./bundleHelpers";
import { MenuCategory, ExtendedMenuCategory, ExtendedMenuSubCategory } from '../types/MenuTypes';

const imageUrlPrefix = "https://image-resized.pizzaexpress.com/";

export const getAvailableCategories = (categories : MenuCategory[], bundles : any[], dietFilters : any[]) => {
    const excludedSubCats = [] as string[];
    const availableCats = categories?.map<ExtendedMenuCategory>(cat => {
        const subCategories = [...cat.subCategories].sort((a, b) => a.displayIndex - b.displayIndex);
        const reducedSubbies = subCategories.reduce<ExtendedMenuSubCategory[]>((acc, cur) => {
            const newCat = { ...cur, itemsToRender: [], bundlesToRender: [] } as ExtendedMenuSubCategory;
            const categoryBundles = filterAvailableBundles(bundles, cur?.name);
            newCat.bundlesToRender.push(...categoryBundles.filter((el: any) => dietFilters.every(pref => el.dietPreferences.includes(pref))));
            // .map((bundle: any) => {
            //   return {...bundle, imageUrl: imageUrlPrefix + bundle.imageUrl};
            // }));
            newCat.itemsToRender.push(...cur.subProducts
                .filter(item => {
                    if (!dietFilters.length) return true;
                    if (dietFilters.every(pref => item.dietPreferences.includes(pref))) return true;
                })
                // .map(item => ({...item, imageUrl: imageUrlPrefix + item.imageUrl})) // LS - Disabled till Stephen approves
            );

            // if (newCat.bundlesToRender.length === 0 && newCat.itemsToRender.length === 0) excludedSubCats.push(cur.name); // MV - disabled on purpose
            acc.push(newCat);
            return acc;
        }, [])
        return { ...cat, subCategories: reducedSubbies }
    });

    return { availableCats, excludedSubCats };
}