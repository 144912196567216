import { useSelector } from 'react-redux';

const PopupBanner = ({ refs, customise, children, hideCheckout, isMenu, justifyEnd }) => {
  const { appInsets } = useSelector(state => state.session);

  return (
    <div
      ref={r => refs?.current ? refs.current.bannerRef = r : null}
      className={`popup-banner ${isMenu ? 'isMenu' : ''} ${customise ? 'customise' : ''}`}
      style={{
        paddingBottom: appInsets?.bottom ? appInsets.bottom : null,
        zIndex: 1001 // Make sure it stays infront of category header, login banner & footer
      }}
    >
      {!hideCheckout && (
        <div className={`popup-banner-container ${justifyEnd ? 'justify-end' : ''}`}>
          {children}
        </div>)}
    </div>
  );
};

export default PopupBanner;

