import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getTags } from "../helpers/basketHelpers";

const initialState = {
  loyaltyTags: [],
  birthdayTags: [],
  uiTags: [],
  hasTags: false,
  lockedBDayTags: [],
  lockedLoyaltyTags: []
};

const getTagsState = (prevState, item, currentTier, loyaltySchemeVersion, showLocked, excludeChildTags, isBirthDayRewardLocked, showLowerTiers) => {
  const visibleUiTags = item?.uiTags?.filter(t => !t.includes("Spicy"));
  
  if (!currentTier) {
    return { ...prevState, uiTags: visibleUiTags ?? [], hasTags: !!visibleUiTags?.length };
  }

  const customerTierText = currentTier.toLowerCase();
  const loyaltyTags = getTags(item, 'loyaltyTags', excludeChildTags)
  const birthdayTags = getTags(item, 'birthdayTags', excludeChildTags);
  if (showLocked) {
    const { tags: loyaltyUnlocked, lockedTags: loyaltyLocked } = sortLockedTags(loyaltyTags, customerTierText, loyaltySchemeVersion);
    const { tags: birthdayUnlocked, lockedTags: birthdayLocked } = sortLockedBirthdayTags(birthdayTags, customerTierText, loyaltySchemeVersion, isBirthDayRewardLocked, showLowerTiers);
    return {
      loyaltyTags: loyaltyUnlocked,
      birthdayTags: birthdayUnlocked,
      uiTags: visibleUiTags ?? [],
      hasTags: loyaltyTags?.length || birthdayTags?.length || visibleUiTags?.length || loyaltyLocked?.length || birthdayLocked?.length,
      lockedBDayTags: birthdayLocked,
      lockedLoyaltyTags: loyaltyLocked
    };
  }
  else {
    const visibleLoyaltyTags = loyaltyTags?.filter(t => checkShouldDisplayLoyaltyTag(customerTierText, loyaltySchemeVersion, t))
      .map(tag => {
        const actualTag = tag.split(':')[0];
        return actualTag.toLowerCase() === 'signup' ? 'Welcome' : actualTag;
      });
    const visibleBirthdayTags = birthdayTags?.filter(t => checkShouldDisplayBirthdayTag(customerTierText, loyaltySchemeVersion, t, isBirthDayRewardLocked, showLowerTiers))
      .map(tag => {
        const actualTag = tag.split(':')[0];
        return actualTag.toLowerCase() === 'signup' ? 'Welcome' : actualTag;
      });
    return {
      ...prevState,
      loyaltyTags: visibleLoyaltyTags ?? [],
      birthdayTags: visibleBirthdayTags ?? [],
      uiTags: visibleUiTags ?? [],
      hasTags: visibleBirthdayTags?.length || visibleLoyaltyTags?.length || item?.uiTags?.length
    };
  }
}

export const useItemTags = (item, currentTier, loyaltySchemeVersion, showLocked = false, excludeChildTags = false, showLowerTiers = false) => {
  const isBirthDayRewardLocked = useSelector(state => state.session?.customer?.isBirthDayRewardLocked);
  if (loyaltySchemeVersion < 1)
    loyaltySchemeVersion = 1;

  const [allTags, setAllTags] = useState(getTagsState(initialState, item, currentTier, loyaltySchemeVersion, showLocked, excludeChildTags, isBirthDayRewardLocked, showLowerTiers));

  useEffect(() => {
    if (!item) return;
    setAllTags(state => getTagsState(state, item, currentTier, loyaltySchemeVersion, showLocked, excludeChildTags, isBirthDayRewardLocked, showLowerTiers));
  }, [item, currentTier])

  return allTags;
}

const TierEnum = {
  signup: 0,
  bronze: 1,
  silver: 2,
  gold: 3
};

const checkShouldDisplayBirthdayTag = (customerTier, loyaltySchemeVersion, itemTier, isBirthDayRewardLocked, showLowerTiers) => {
  const tagDetails = itemTier.split(':');
  const actualTag = tagDetails[0];
  const tagScheme = parseInt(tagDetails[1]);
  if (loyaltySchemeVersion != tagScheme) return false;

  return !isBirthDayRewardLocked && (showLowerTiers ? TierEnum[customerTier] <= TierEnum[actualTag] : customerTier === actualTag.toLowerCase());
}

const checkShouldDisplayLoyaltyTag = (customerTier, loyaltySchemeVersion, itemTier) => {
  const tagDetails = itemTier.split(':');
  const actualTag = tagDetails[0];
  const tagScheme = parseInt(tagDetails[1]);
  if (loyaltySchemeVersion != tagScheme) return false;

  return customerTier === actualTag.toLowerCase();
}

const isTagLocked = (customerTier, itemTier) => {
  if (!customerTier || !itemTier) return false;
  if (TierEnum[customerTier] >= TierEnum[itemTier]) return false;
  else return true;
}

const isTagBelowCurrentTier = (customerTier, itemTier) => {
  if (!customerTier || !itemTier) return true;
  if (TierEnum[customerTier] > TierEnum[itemTier]) return true;
  else return false;
}

const sortLockedTags = (tags, currentTier, loyaltySchemeVersion, showLowerTiers) => {
  if (!tags?.length) return { tags: [], lockedTags: [] };
  return tags.reduce((acc, cur) => {
    const tagDetails = cur.split(':');
    const actualTag = tagDetails[0];
    const tagScheme = parseInt(tagDetails[1]);
    if (loyaltySchemeVersion != tagScheme) return acc;

    const lowerCaseTag = actualTag.toLowerCase();
    const friendlyTag = lowerCaseTag === 'signup' ? 'Welcome' : actualTag;
    if (!showLowerTiers && isTagBelowCurrentTier(currentTier, lowerCaseTag)) return acc;
    if (isTagLocked(currentTier, lowerCaseTag)) acc.lockedTags.push(friendlyTag);
    else acc.tags.push(friendlyTag);
    return acc;
  }, { tags: [], lockedTags: [] })
}

const sortLockedBirthdayTags = (tags, currentTier, loyaltySchemeVersion, isBirthDayRewardLocked, showLowerTiers = false) => {
  if (!tags?.length) return { tags: [], lockedTags: [] };
  return tags.reduce((acc, cur) => {
    const tagDetails = cur.split(':');
    const actualTag = tagDetails[0];
    const tagScheme = parseInt(tagDetails[1]);
    if (loyaltySchemeVersion != tagScheme) return acc;

    const lowerCaseTag = actualTag.toLowerCase();
    const friendlyTag = lowerCaseTag === 'signup' ? 'Welcome' : actualTag;
    if (!showLowerTiers && isTagBelowCurrentTier(currentTier, lowerCaseTag)) return acc;
    if (isTagLocked(currentTier, lowerCaseTag) || isBirthDayRewardLocked) acc.lockedTags.push(friendlyTag);
    else acc.tags.push(friendlyTag);
    return acc;
  }, { tags: [], lockedTags: [] })
}