import api from './api';
import { MenuState, MenuStateRequest } from '../types/MenuTypes';

export const menuApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMenuState: builder.query<MenuState, MenuStateRequest>({
      query: ({restaurantId, etag}) => `menu/${restaurantId}?etag=${etag}`,
      providesTags: (menu) => menu
        ? ['MenuState']
        : []
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMenuStateQuery,
  useLazyGetMenuStateQuery,
  usePrefetch
} = menuApi;