import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../helpers/commonHelpers';
import { applicationApi } from '../services/application.api';
import { customerApi } from '../services/customer.api';
import { Restaurant } from '../types/RestaurantTypes';
import { Customer } from '../types/ApplicationTypes';

const cookiesAccepted = getCookie('cookie_accepted');
const inApp = getCookie('OOP_IN_APP');

const initialState = {
  basketId: null,
  loggedInUser: null,
  customer: null,
  user: {},
  restaurantId: null,
  restaurant: {},
  checkoutCompletePath: '/',
  collectionTime: '',
  customerFacingOrderId: '',
  menuScrollPosition: 0,
  features: {
    delivery: false, // Controlls if /delivery urls work and the journey is enabled
    loyalty: false, // All Loyalty UI show/hide
    showStackedRewards: false, //For stacked rewards
  },
  captcha: false,
  cookiesAccepted: cookiesAccepted ? cookiesAccepted : false,
  freshRelevanceInitialised: false,
  hasShownEmployeeModal: false,
  deliveryTimeUpdated: false,
  cmsConfig: {},
  inApp: !!inApp,
  hasSeenBasket: false,
  searchTerm: '',
  isSmallScreen: false,
  isTabletScreen: false,
  searchResults: null,
  skipAppState: false,
  appInsets: null,
  deliveryAddress: null,
  deliverySession: {},
  frontEndConfig: {},
  bundleQuantity: 1
} as {
  basketId: string | null,
  loggedInUser: object | null,
  customer: Customer | null,
  user: object | null,
  restaurantId: string | null,
  restaurant: Restaurant | null,
  checkoutCompletePath: string,
  collectionTime: string,
  customerFacingOrderId: string,
  menuScrollPosition: number,
  features: {
    delivery: boolean,
    loyalty: boolean,
    showStackedRewards: boolean,
    showRewardComparePage: boolean
  },
  captcha: boolean,
  cookiesAccepted: boolean,
  freshRelevanceInitialised: boolean,
  hasShownEmployeeModal: boolean,
  deliveryTimeUpdated: boolean,
  cmsConfig: any,
  inApp: boolean,
  hasSeenBasket: boolean,
  searchTerm: string,
  isSmallScreen: boolean,
  isTabletScreen: boolean,
  bannerHeight: number,
  navMenuHeight: number,
  searchResults: object[] | null,
  skipAppState: boolean,
  appInsets: any | null,
  deliveryAddress: string | null,
  deliverySession: object,
  frontEndConfig: object,
  bundleQuantity: number
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    clearUser(state) {
      state.user = null;
      state.collectionTime = '';
    },
    setRestaurant(state, action) {
      state.restaurantId = action.payload.id;
      if (action.payload.id !== null && action.payload.object !== null) {
        state.restaurant = action.payload.object;
      }
    },
    setRestaurantObject(state, action) {
      state.restaurant = action.payload;
    },
    setRestaurantId(state, action) {
      state.restaurantId = action.payload.id;
      // @ts-ignore
      state.restaurant = { ...state.restaurant, id: action.payload.id };
    },
    setSessionSearchResults(state, action) {
      state.searchResults = action.payload;
    },
    setCheckoutCompletePath(state, action) {
      state.checkoutCompletePath = action.payload;
    },
    resetCheckoutCompletePath(state) {
      if (state.restaurantId) {
        state.checkoutCompletePath = `/menu/${state.restaurantId}`;
      } else {
        state.checkoutCompletePath = '/';
      }
    },
    setCollectionTime(state, action) {
      state.collectionTime = action.payload;
    },
    setAddress(state, action){
      state.deliveryAddress = action.payload;
    },
    reloadBasket(state, { payload }) {
      state.restaurant = payload.restaurant;
      if (state.restaurant) {
        state.restaurantId = payload.restaurant.id;
      }
      state.user = payload.customer;
    },
    setMenuScrollPosition(state, { payload }) {
      state.menuScrollPosition = payload;
    },
    setDeliverySessionState(state, { payload }) {
      state.deliverySession = payload;
    },
    resetDeliverySession(state) {
      state.deliverySession = {};
    },
    initialiseFreshRelevance(state) {
      state.freshRelevanceInitialised = true;
    },
    setHasShownEmployeeModal(state, { payload }) {
      state.hasShownEmployeeModal = payload;
    },
    setUpdatedDeliveryTime(state, { payload }) {
      state.deliveryTimeUpdated = payload;
    },
    setHasSeenBasket(state, { payload }) {
      state.hasSeenBasket = payload;
    },
    setIsSmallScreen(state, { payload}) {
      state.isSmallScreen = payload;
    },
    setIsTabletScreen(state, { payload}) {
      state.isTabletScreen = payload;
    },
    resetSessionSearchData(state) {
      state.searchResults = [];
      state.searchTerm = '';
      state.skipAppState = true;
    },
    setAppInsets(state, {payload}) {
      state.appInsets = payload;
    },
    setBundleQuantity(state, {payload}) {
      state.bundleQuantity = payload
    }
  },
  // Listens for API to be called else where and loads result into state
  extraReducers: builder => {
    builder.addMatcher(
      applicationApi.endpoints.getCustomerState.matchFulfilled,
      (state, { payload }) => {
        state.customer = payload.customer;
      }
    );
    builder.addMatcher(
      applicationApi.endpoints.getApplicationState.matchFulfilled,
      (state, { payload }) => {
        if (!state.skipAppState) {
          state.basketId = payload.basket?.basketId;
          state.restaurant = payload.restaurant;
          if (state.restaurant) {
            state.restaurantId = payload.restaurant.id;
          }
          state.features = payload.features;
          state.cmsConfig = payload.cmsConfig;
          state.captcha = payload.captcha;
          state.frontEndConfig = payload.frontEndConfig
          if (payload.basket?.basket?.delivery) {
            state.deliveryTimeUpdated = payload.basket.basket.delivery.deliveryQuote.updated;
          }
          const newSearchTerm = payload?.basket?.basket?.searchTerm;
          if (newSearchTerm) {
            state.searchTerm = newSearchTerm;
          }
        }
        state.skipAppState = false;
      }
    );
    builder.addMatcher(
      customerApi.endpoints.registerUser.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.validate2FA.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.logout.matchFulfilled,
      () => initialState
    );
    builder.addMatcher(
      customerApi.endpoints.mobileUpdate.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 'OK') state.customer = { ...state.customer, ...payload };
      }
    );
    builder.addMatcher(
      customerApi.endpoints.acceptCookies.matchFulfilled,
      (state) => {
        state.cookiesAccepted = true;
      }
    );
  }
});

export const {
  setSessionSearchTerm,
  clearUser,
  setRestaurant,
  setRestaurantObject,
  setRestaurantId,
  setCheckoutCompletePath,
  resetCheckoutCompletePath,
  setCollectionTime,
  setAddress,
  reloadBasket,
  setMenuScrollPosition,
  setDeliverySessionState,
  resetDeliverySession,
  initialiseFreshRelevance,
  setHasShownEmployeeModal,
  setUpdatedDeliveryTime,
  setHasSeenBasket,
  setIsSmallScreen,
  setIsTabletScreen,
  setSessionSearchResults,
  resetSessionSearchData,
  setAppInsets,
  setBundleQuantity
} = sessionSlice.actions;
export default sessionSlice.reducer;