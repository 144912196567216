import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Basket from './pages/Basket';
import { useSelector } from 'react-redux';
import { historyMW } from '../../helpers/routingHelpers';
import { freshRelevance, gtmEvent } from '../../helpers/commonHelpers';
import { useEffect } from 'react';

const BasketController = ({ onAddItem, onRemoveItem, selectingRewards, refs }) => {
  const restaurantId = useSelector(state => state.session.restaurant.id);
  const basket = useSelector(state => state.basket);
  const appApproved = useSelector(state => state.session?.customer?.appApproved);
  const loyalty = useSelector(state => state.session?.features.loyalty) && appApproved;
  const { total, subTotal, voucherCodes, voucherReduction } = useSelector(state => state.basket);
  const { isDelivery } = useSelector(state => state.basket);
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();

  const navigateTo = (path, state = {}) => {
    historyMW.push(path, isDelivery, navigate, state);
  };

  useEffect(() => {
    gtmEvent(isDelivery ? 'Delivery_basket_summary' : 'Collection_basket summary');
  }, []);

  const handleCheckout = async (hasSelectedRewards, isLoyalty) => {
    freshRelevance('pageChange', null, {'sendBeacon': true});
    const params = { hasSelectedRewards, isLoyalty };
    gtmEvent(isDelivery ? 'Delivery_Proceed_Beyond_Basket' : 'Collection_Proceed_Beyond_Basket', params);

    if (loyalty && !selectingRewards) {
      historyMW.push('/loyalty', isDelivery, navigate);
    } else {
      historyMW.push('/payment', isDelivery, navigate);
    }
  };

  return (
    <Basket
      restaurantId={restaurantId}
      basketItems={basket.bundles.concat(basket.items)}
      numberOfItems={basket.numberOfItems}
      total={total}
      subTotal={subTotal}
      handleCheckout={handleCheckout}
      addItem={onAddItem}
      removeItem={onRemoveItem}
      navigateTo={navigateTo}
      screenWidth={width}
      location={location}
      voucherCodes={voucherCodes}
      voucherReduction={voucherReduction}
      selectingRewards={selectingRewards}
      loyalty={loyalty}
      isDelivery={isDelivery}
      deliveryFee={basket.delivery?.deliveryFeeItem}
      refs={refs}
    />
  );
};

export default BasketController;
