import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { historyMW } from '../helpers/routingHelpers';
import PopupBanner from './PopupBanner';

const PopupBannerController = ({
  customise,
  screenWidth,
  basket,
  children,
  hideCheckout,
  popupOffset,
  isMenu,
  justifyEnd,
  refs
}) => {
  const navigate = useNavigate();
  const basketTotal = useSelector(state => state.basket.subTotal);
  const { isDelivery } = useSelector(state => state.basket);

  const navigateTo = (path) => {
    historyMW.push(path, isDelivery, navigate);
  };

  return <PopupBanner
    refs={refs}
    customise={customise}
    screenWidth={screenWidth}
    basket={basket}
    navigateTo={navigateTo}
    basketTotal={basketTotal}
    hideCheckout={hideCheckout}
    popupOffset={popupOffset}
    isMenu={isMenu}
    justifyEnd={justifyEnd}
  >
    {children}
  </PopupBanner>;
};

export default PopupBannerController;
