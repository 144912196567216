import React, { useEffect, useState, useRef } from 'react';
import BasketUpsellCard from '../../../components/BasketUpsellCard';
import VoucherRedeem from '../../../components/VoucherRedeem';
import Carousel, { CarouselItem } from '../../../components/Carousel';
import PopupBannerController from '../../../components/PopupBannerController';
import InfoPopOver from '../../../components/InfoPopOver';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import Layout from '../../../components/Layout';
import { useGetUpsellItemsQuery } from '../../../services/basket.api';
import { getQuickAddProperties, getUniqueItems } from '../../../helpers/basketHelpers';
import { useRemoveOutOfStockItemsFromBasketMutation } from '../../../services/basket.api';
import { useSelector, useDispatch } from 'react-redux';
import LoyaltySlider from '../../../components/loyalty/LoyaltySlider';
import TotalSavings from '../components/TotalSavings';
import { EmployeeDiscountModalWrapper, GenericModalWrapper, MinimumOrderModalWrapper } from '../../../helpers/modalHelpers';
import { useConfig } from '../../../helpers/useConfig';
import { setCollectionTime, setHasSeenBasket } from '../../../store/sessionSlice';
import infoIcon from '../../../img/icons/iconInfo.svg';
import { useSafeState } from '../../../hooks/useIsMounted';
import clubRewardsStar from '../../../img/icons/ClubRewardsStar.svg';
import clubRewardsStarWhite from '../../../img/icons/ClubRewardsStar-white.svg';
import { BasketItem } from '../../../components/BasketItem';
import { historyMW } from '../../../helpers/routingHelpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { gtmEvent } from '../../../helpers/commonHelpers';
import { DiscountSummary } from '../../../components/DiscountSummary';
import { useClearDiscountsMutation, useSaveDiscountsMutation } from '../../../services/loyalty.api';
import { updateBasket } from '../../../store/basketSlice';
import { useAppSelector } from '../../../store/storeTypes';

const Basket = ({
  restaurantId,
  handleCheckout,
  basketItems,
  subTotal,
  addItem,
  removeItem,
  navigateTo,
  screenWidth,
  numberOfItems,
  voucherCodes,
  voucherReduction,
  selectingRewards,
  loyalty,
  isDelivery,
  deliveryFee,
  refs
}) => {
  const config = useConfig();
  const [rewardData, setRewardData] = useSafeState(null);
  const inApp = useAppSelector(state => state.session.inApp);
  const [removeOutOfStockItemsFromBasket, { isLoading: isRemovingFromBasket }] = useRemoveOutOfStockItemsFromBasketMutation();
  const { freshRelevanceInitialised, hasShownEmployeeModal, customer } = useSelector(state => state.session);
  const isSmallScreen = useBreakPoint(screenWidth, 555);
  const isBelowTablet = useBreakPoint(screenWidth, 768);
  const { data: upsellItems, isSuccess } = useGetUpsellItemsQuery({ id: restaurantId, type: 'Upsell' });
  const outOfStockBasketItems = basketItems.filter(item => item.outOfStock || item.doesNotExistInCurrentMenu);
  const inStockBasketItems = basketItems.filter(item => !item.outOfStock && !item.doesNotExistInCurrentMenu);
  const tierName = useSelector(state => state.loyalty?.customer?.currentTier?.name);
  const discounts = useSelector(state => state.basket?.discounts);
  const email = useSelector(state => state.basket?.email);
  const popupModalIds = useSelector(state => state.session.frontEndConfig?.popupModalIds);
  const [rewards, setRewards] = useSafeState(null);
  const [notEnoughItemsOpen, setNotEnoughItemsOpen] = useState(false);
  const [doesNotMeetMinimumOrder, setDoesNotMeetMinimumOrder] = useState(false);
  const [doesNotMeetMinimumOrderModalOpen, setDoesNotMeetMinimumOrderModalOpen] = useState(false);
  const [subTotalToDisplay, setSubTotalToDisplay] = useState(subTotal);
  const [ageRestricted, setAgeRestricted] = useState(false);
  const infoSubtotalWrapperRef = useRef({current: null});
  const [wrapperFullWidth, setWrapperFullWidth] = useState(388);
  const infoSubtotalContainer = useRef({});
  const dispatch = useDispatch();
  const pageRef = useRef({});
  const basketItemsToRender = (deliveryFee && !inApp && (discounts.some(discount => discount.id === deliveryFee.customID || discount.id === deliveryFee.id))) ? [...basketItems, deliveryFee] : basketItems;
  const navigate = useNavigate();
  const shouldShowRFM = isSuccess && upsellItems && !selectingRewards && !freshRelevanceInitialised && upsellItems?.upsell?.length > 0;
  const [tempBasket, setTempBasket] = useState({});
  const [initialTempBasketHash, setInitialTempBasketHash] = useState();
  const tempBasketEdited = initialTempBasketHash !== tempBasket.tempBasketHash;
  const [saveDiscounts] = useSaveDiscountsMutation();
  const [clearDiscounts] = useClearDiscountsMutation();
  const [isDiscountsOpen, setIsDiscountsOpen] = useState(false);
  const filteredDiscounts = inApp ? discounts?.filter(d => d.offerTitle !== 'Free Delivery') : discounts;
  const { pathname } = useLocation();  

  useEffect(() => {
    dispatch(setHasSeenBasket(true));
    dispatch(setCollectionTime(''));
  }, []);
  
  useEffect(() => {
    setDoesNotMeetMinimumOrder(isDelivery && subTotalToDisplay < 10);
    setDoesNotMeetMinimumOrderModalOpen(isDelivery && subTotalToDisplay < 10);
  }, [isDelivery, subTotalToDisplay]);

  const updateWidth = () => {
    if (infoSubtotalWrapperRef.current) {
      const newWidth = infoSubtotalWrapperRef.current.offsetWidth;
      setWrapperFullWidth(newWidth);
    }
  };

  const handleResize = () => {
    updateWidth();
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    setSubTotalToDisplay(valueWithAppliedRewards(subTotal - voucherReduction).toFixed(2));
  }, [subTotal, discounts, voucherReduction]);

  useEffect(() => {
    setAgeRestricted(basketItems.some(b => {
      if (b.sections) {
        const flattenedProducts = b.sections.flatMap(s => s.products.flatMap(p => p.product));
        return flattenedProducts.some(p => p.ageRestricted);
      } else {
        return b.ageRestricted;
      }
    }));
  }, [basketItems]);

  const handleRemoveOutOfStockItems = async () => {
    await removeOutOfStockItemsFromBasket();
  };

  const handleGoBackToMenuPage = () => {
    gtmEvent(isDelivery ? 'Delivery_Add_More_Items' : 'Collection_Add_More_Items');
    navigateTo(`/menu/${restaurantId}`);
  };

  const getDiscountsTotal = (completeOnly = false) => {
    let rewardTotal = 0;
    if (filteredDiscounts && filteredDiscounts?.length > 0) {
      filteredDiscounts.forEach(discount => {
        if(completeOnly) {
          const reward = rewardData?.sections?.find(el => el.title === discount.offerTitle);
          const isCompleted = reward?.subSections?.flatMap(el => el.offers).every(el => el.groupComplete) ?? false;
          if(isCompleted) rewardTotal += discount.amount;
        } else {
          rewardTotal += discount.amount;
        }
      });
    }
    return rewardTotal;
  };

  const valueWithAppliedRewards = (value) => {
    const rewardTotal = getDiscountsTotal();
    let subTotalAppliedRewards = value - rewardTotal;
    const deliveryFeeDiscount = discounts?.find(d => d.offerTitle === 'Free Delivery');
    if(deliveryFeeDiscount && inApp) subTotalAppliedRewards -= deliveryFeeDiscount.amount;
    return subTotalAppliedRewards;
  };

  const employeeDiscountApplied = voucherCodes?.some(v => v.name === 'Employee Discount');

  const handleCommitRewards = async (checkout = true, bypass = false) => {
    if (inApp && (tempBasketEdited || bypass)) {
      const result = await saveDiscounts(tempBasket);
      if (result.updateBasketResponse) {
        dispatch(updateBasket(rewards.updateBasketResponse));
      }
    }
    if(checkout) handleCheckout(!!selectingRewards, !!loyalty);
  };

  const handleClearDiscounts = async () => {
    const result = await clearDiscounts(tempBasket);
    if (result?.data?.updateBasketResponse) {
      dispatch(updateBasket(result.data.updateBasketResponse));
      const newRewards = result.data;
      const correctedRewards = {
        alternativeOverMessage: newRewards.excludedCampaignIds?.length > 0 && !newRewards.nonExcludedCampaignsSelectedByThisUser,
        ...newRewards
      };
      setRewardData(correctedRewards);
      setRewards(correctedRewards);
      const { tempBasketJSON, tempBasketHash } = result?.data;
      setInitialTempBasketHash(tempBasketHash);
      setTempBasket({ tempBasketJSON, tempBasketHash });
    }
  };

  const getPaddingForBannerSize = () => {
    if (!isBelowTablet) return 0;
    else if (!inApp || shouldShowRFM) return 20;
    return (refs.current.bannerRef?.clientHeight ?? 0) + 15;
  };

  return (
    <div ref={pageRef} style={{ position: 'relative' }}>
      <Layout
        title={!selectingRewards ? 'Basket summary' : 'Applying rewards & Offers'}
        isFluid={true}
        customStyles='is-basket'
        backgroundColour={inApp ? 'malbec-100' : 'transparent'}
        titleColour={inApp ? 'rose-100' : 'black'}
        sectionSize={'is-padless-top is-padless-bottom'}
        isBasket
        backButton={selectingRewards
          ? () => historyMW.push('/basket', isDelivery, navigate)
          : () => historyMW.push(`/menu/${restaurantId}`, isDelivery, navigate)
        }
      >
        <div className={`container basket-container ${!shouldShowRFM ? 'no-rfm' : ''}`} style={{ minHeight: '60vh', paddingBottom: isSmallScreen ? selectingRewards ? 0 : inApp ? 129 : 50 : selectingRewards ? 100 : 50, marginBottom: isSmallScreen ? '-5px' : 0 }}>
          <section className="section is-small is-padless-top" style={{ paddingBottom: getPaddingForBannerSize() }}>
            <div className="block columns basket-summary-columns" style={{ paddingTop: '1rem' }}>
              <div className="column is-8-desktop is-6-tablet is-12-mobile basket-container-left" >
                {pathname.includes('/loyalty') && inApp && voucherCodes.length > 0 &&
                      <div className='basket-subtotals-item' style={{ padding: '32px 24px' }}>
                        <VoucherRedeem rewardsApplied={discounts || []} inApp={inApp} backgroundColor='rgba(215, 210, 203, 0.2)' />
                      </div>
                }
                <div className='basket-content-container p-3'>
                  {!selectingRewards ?
                    <div className="columns is-flex-direction-column" style={{ padding: isSmallScreen ? '24px' : '2rem 2rem 3rem 2rem' }}>
                      {outOfStockBasketItems.length > 0 && (
                        <>
                          <p className="subtitle is-text-weight-bold" style={{ color: '#1c1a1a' }}>The following products are out of stock or unavailable:</p>
                          {outOfStockBasketItems
                            .map((item, i) => (
                              <React.Fragment key={'pizza-' + i}>
                                <BasketItem
                                  id={'pizza-' + i}
                                  index={i}
                                  item={item}
                                  columnWidth={12}
                                  addItem={addItem}
                                  screenWidth={screenWidth}
                                  removeItem={removeItem}
                                  navigateTo={navigateTo}
                                  isBundle={Object.prototype.hasOwnProperty.call(item, 'eposDiscountId')}
                                  outOfStock={true}
                                  hideCalories
                                  discounts={discounts}
                                />
                                {
                                  i !== basketItems.length - 1 &&
                                  <hr style={{ backgroundColor: 'rgba(215, 210, 203, 0.6)', margin: `${isSmallScreen ? '32px -32px' : '32px 0'}` }} />
                                }
                              </React.Fragment>
                            ))}
                          <div className="pb-6" style={{ textAlign: 'center' }}>
                            <button
                              onClick={handleRemoveOutOfStockItems}
                              className='btn btn-secondary'
                              data-testid={'removeoutofstockitems'}
                              disabled={isRemovingFromBasket}
                            >
                              Remove above items
                            </button>
                          </div>
                          {inStockBasketItems.length > 0 &&
                            (<p className="subtitle is-text-weight-bold" style={{ color: '#1c1a1a' }}>The following products are in stock and available:</p>)}
                        </>
                      )}
                      {
                        ageRestricted && isDelivery &&
                        <div
                          style={{
                            width: '100%',
                            backgroundColor: '#FFE9AE',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            gap: 10,
                            padding: '1rem',
                            marginBottom: '1.5rem'
                          }}
                          data-testid='basket-alcohol-warning'
                        >
                          <img id='upperAndLowerIndicator' src={infoIcon} alt="info" />
                          <p style={{ fontSize: '14px', color: '#796429' }}>You must be at least 18 to purchase alcohol and show a valid ID at delivery. The name on the delivery order should match the ID. Failure to present a valid ID may result in your order being returned.</p>
                        </div>
                      }
                      {inStockBasketItems
                        .map((item, i) => (
                          <React.Fragment key={'pizza-' + i}>
                            <BasketItem
                              id={'pizza-' + i}
                              index={i}
                              item={item}
                              columnWidth={12}
                              addItem={addItem}
                              removeItem={removeItem}
                              navigateTo={navigateTo}
                              isBundle={Object.prototype.hasOwnProperty.call(item, 'eposDiscountId')}
                              screenWidth={screenWidth}
                              hideCalories
                              discounts={discounts}
                            />
                            {
                              i !== basketItems.length - 1 &&
                              <hr style={{ backgroundColor: 'rgba(215, 210, 203, 0.6)', margin: `${isSmallScreen ? '32px -32px' : '32px 0'}` }} />
                            }
                          </React.Fragment>
                        ))}
                      {basketItems.length === 0 && (
                        <>
                          <div>
                            <p className="subtitle pt-6 pr-6 pl-6" style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#1c1a1a' }}>
                              Oops! you have not selected any items.
                            </p>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                              <p className="pt-6 pr-6 pl-6 pb-6">
                                <button
                                  onClick={handleGoBackToMenuPage}
                                  className='btn btn-secondary'
                                  data-testid={'tomenu'}
                                >
                                  Add more items
                                </button>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div> : <LoyaltySlider
                      rewardData={rewardData}
                      setRewardData={setRewardData}
                      hasVouchersInBasket={voucherCodes}
                      onGetRewards={setRewards}
                      discountTotal={getDiscountsTotal()}
                      isDelivery={isDelivery}
                      tempBasket={tempBasket}
                      setTempBasket={setTempBasket}
                      rewardsInfo={rewards}
                      setInitialTempBasketHash={setInitialTempBasketHash}
                      commitChanges={handleCommitRewards}
                    />}
                </div>
              </div>
              <div className="column is-flex is-flex-direction-column is-4-desktop is-6-tablet is-12-mobile p-0 basket-container-right">
                {
                  (!selectingRewards && loyalty) &&
                  <div className={`basket-content-container is-reward-card reward-tier-${tierName}`}>
                    <div className='is-flex' style={{ gap: '1rem' }}>
                      <div className='is-flex is-flex-direction-column' style={{ gap: '1rem' }}>
                        <h2 className={'title voucher-redeem-title mb-0-tablet'}>Rewards & Offers</h2>
                        <p className='dm-sans'>Apply your PizzaExpress Club Rewards & Offers on the next screen</p>
                      </div>
                      <img src={tierName === 'SignUp' ? clubRewardsStarWhite : clubRewardsStar} />
                    </div>
                  </div>
                }
                <div className='basket-content-container p-0'>
                  <>
                    {(pathname.includes('/basket') || !inApp) &&
                      <div className='basket-subtotals-item' style={{ padding: '32px 24px' }}>
                        <VoucherRedeem rewardsApplied={discounts || []} title={'Got a Voucher?'} backgroundColor='rgba(215, 210, 203, 0.2)' />
                      </div>
                    }
                    {voucherCodes?.map((v, i) => (
                      <div
                        key={i}
                        className="is-size-6 is-text-weight-semibold basket-subtotals-item"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <span className='dm-sans'>{v.name}</span>
                        {
                          v.reduction > 0 &&
                            <span className='is-size-5'>-{config?.CURRENCY_SYMBOL}{v.reduction}</span>
                        }
                      </div>
                    ))}
                    {!!voucherCodes && voucherCodes.length > 0 && (
                      <>
                        {!!voucherReduction &&
                            <div
                              className="column is-12"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontWeight: '600'
                              }}>
                              <span>Total Savings</span>
                              <span
                                style={{
                                  fontWeight: '400'
                                }}
                              >
                                - {config?.CURRENCY_SYMBOL}{voucherReduction?.toFixed(2)}
                              </span>
                            </div>
                        }
                      </>
                    )}
                  </>
                  {(selectingRewards && loyalty) && <TotalSavings discounts={filteredDiscounts} basketItems={basketItemsToRender} />}
                  <div ref={infoSubtotalWrapperRef} className="is-size-6 is-text-weight-semibold basket-subtotals-item">
                    <div ref={infoSubtotalContainer} className='is-flex is-align-items-center' style={{ gap: 10 }}>
                      <span className='dm-sans'>
                        Subtotal
                      </span>
                      {isDelivery && <InfoPopOver
                        text="This subtotal reflects the total price of your order. It does not include delivery fee and service fee."
                        parentRef={infoSubtotalContainer}
                        maxWidth={wrapperFullWidth}
                      />}
                    </div>
                    <span style={{ marginLeft: 8, fontSize: 28, fontWeight: 400 }} className='subtotal'>
                      {config?.CURRENCY_SYMBOL}
                      {subTotalToDisplay}
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </section>
          {
            shouldShowRFM &&
            <div className='section is-small room-for-more-section' >
              <Carousel
                autoplay={false}
                allowReduceForSmallerScreens
                hasOffsetMobile
                title={'ROOM FOR MORE?'}>
                {getUniqueItems(upsellItems?.upsell).filter(item => getQuickAddProperties(item, popupModalIds).isAddable).slice(0, 9).map((upsell, i) => (
                  <CarouselItem key={i}>
                    <BasketUpsellCard
                      id={'upsell-' + i}
                      index={i}
                      item={{ ...upsell, cost: upsell.displayPrice, outOfStock: false }}
                      columnWidth={4}
                      imageWidthDesktop={12}
                      imageWidthTablet={12}
                      addItem={addItem}
                    />
                  </CarouselItem>
                ))}
              </Carousel>
            </div>
          }
        </div>
        <div ref={r => refs.current.footerSpacer = r} style={{ height: 200, width: '100%' }} />
        {
          !outOfStockBasketItems?.length &&
          (<PopupBannerController refs={refs} screenWidth={screenWidth}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 24 }}>
              {
                inApp && selectingRewards && discounts?.length ?
                  <DiscountSummary isOpen={isDiscountsOpen} setIsOpen={setIsDiscountsOpen} filteredDiscounts={filteredDiscounts} discounts={discounts} subTotal={subTotalToDisplay} />
                  : null
              }
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '1rem' }}>
                {
                  selectingRewards
                    && inApp ?
                    <button
                      disabled={!discounts?.length}
                      onClick={handleClearDiscounts}
                      className='btn btn-primary btn-inverted-primary variable-btn-width'
                      data-testid={'clearSelection'}
                      id='addMoreItemsButton'
                    >
                      Clear Selection
                    </button>
                    :
                    <button
                      onClick={handleGoBackToMenuPage}
                      className='btn btn-primary btn-inverted-primary variable-btn-width'
                      data-testid={'tobasket'}
                      id='addMoreItemsButton'
                    >
                      Add more items
                    </button>
                }
                {
                  !selectingRewards
                    && numberOfItems > 0
                    && !doesNotMeetMinimumOrder
                    ? <button
                      onClick={() => handleCheckout(!!selectingRewards, !!loyalty)}
                      data-testid={'tocheckout'}
                      className={'checkout-btn btn btn-primary variable-btn-width'}
                      id='checkoutButton'>Checkout</button>
                    : <></>
                }
                {
                  selectingRewards
                    && !loyalty
                    && numberOfItems > 0
                    && !doesNotMeetMinimumOrder
                    ? <button
                      onClick={() => handleCheckout(!!selectingRewards, !!loyalty)}
                      data-testid={'tocheckout'}
                      className={'checkout-btn btn btn-primary variable-btn-width'}
                      id='checkoutButton'>Checkout</button>
                    : <></>
                }
                {
                  selectingRewards
                    && loyalty
                    && rewards
                    && numberOfItems > 0
                    && rewards.selectionComplete
                    && (getDiscountsTotal() == 0 || rewards?.over <= 0 || rewards?.alternativeOverMessage && rewards?.selectionComplete)
                    && !doesNotMeetMinimumOrder
                    ? <button
                      onClick={handleCommitRewards}
                      data-testid={'tocheckout'}
                      className={'checkout-btn btn btn-primary variable-btn-width'}
                      id='checkoutButton'>{inApp && filteredDiscounts?.length ? `Save £${getDiscountsTotal().toFixed(2)}` : 'Checkout'}</button>
                    : <></>
                }
                {/* case below added in the event of desync with Eagle Eye */}
                {
                  selectingRewards
                    && loyalty
                    && !rewards
                    && numberOfItems > 0
                    && (getDiscountsTotal() == 0)
                    && !doesNotMeetMinimumOrder
                    ? <button
                      onClick={handleCheckout}
                      data-testid={'tocheckout'}
                      className={'checkout-btn btn btn-primary variable-btn-width'}
                      id='checkoutButton'>Checkout</button>
                    : <></>
                }
                {
                  selectingRewards
                    && ((loyalty && rewards && numberOfItems > 0
                      && getDiscountsTotal() > 0 && rewards.over > 0 && !rewards.alternativeOverMessage)
                      || (rewards && !rewards.selectionComplete))
                    ? <button
                      disabled={!rewards.selectionComplete || subTotalToDisplay < 10}
                      onClick={() => setNotEnoughItemsOpen(true)}
                      data-testid={'tocheckout'}
                      className={'checkout-btn btn btn-primary variable-btn-width'}
                      id='checkoutButton'>{inApp  && filteredDiscounts?.length ? `Save £${getDiscountsTotal(true).toFixed(2)}` : 'Checkout'}</button>
                    : <></>
                }
              </div>
            </div>
          </PopupBannerController>)}

        <GenericModalWrapper
          isOpen={notEnoughItemsOpen}
          setIsOpen={setNotEnoughItemsOpen}
          title="You've not selected enough items"
          message="One or more of your rewards requires multiple product selection, please check your rewards and select the correct number of items before you save again."
          buttonTitle="Continue selection"
          inApp={inApp} />
          
        <MinimumOrderModalWrapper
          restaurantId={restaurantId}
          testId='minimum-delivery-modal'
          isOpen={doesNotMeetMinimumOrderModalOpen}
          setIsOpen={setDoesNotMeetMinimumOrderModalOpen}
          navigateTo={navigateTo}
          title="Order below minimum delivery amount"
          message="Your order is below the minimum delivery amount. Please bring the total to £10 or more to continue"
          inApp={inApp}
          customer={customer}
          gtmEvent={gtmEvent}
          isDelivery={isDelivery}
        />
        {!employeeDiscountApplied && email && !isDelivery && !hasShownEmployeeModal && customer && customer.employeeDiscountCode && <EmployeeDiscountModalWrapper inApp={inApp} isOpen={!hasShownEmployeeModal} />}
      </Layout>
    </div >
  );
};

export default Basket;
