import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/storeTypes';
import { throttle } from '../../../helpers/commonHelpers';
import { setMenuScrollPosition } from '../../../store/sessionSlice';
import FilterMenuButton from '../../../components/FilterButton';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import MenuSubHeader from './MenuSubHeader';

const MenuHeader = ({ menuCategories, isLoadingCategories, selectedCategory, selectedSubCategory, setDietModalIsOpen, filters, inApp, scrollToCategory, scrollToSubCategory, refs }) => {
  const [categoryWrapperRef, setCategoryWrapperRef] = useState();
  const [addSpacer, setAddSpacer] = useState(false);
  const [spacerWidth, setSpacerWidth] = useState(0);
  const { width } = useWindowDimensions();
  const isTabletScreen = useAppSelector(state => state.session.isTabletScreen);
  const [initialSelectCategory, setInitialSelectCategory] = useState(true);
  const menuPosition = useAppSelector(state => state.session.menuScrollPosition);
  const dispatch = useAppDispatch();
  const excludedCategories = useAppSelector(state => state.menu.excludedCategories);
  const scrollPadding = 20;
  const setNewMenuPosition = (position) => {
    dispatch(setMenuScrollPosition(position));
  };

  const throttledSetMenuPosition = throttle(setNewMenuPosition, 200);

  const scrollListenerCategory = (e) => {
    e.preventDefault();
    const newScroll = categoryWrapperRef.scrollLeft + e.deltaY / 2;
    categoryWrapperRef.scrollLeft = newScroll;
    throttledSetMenuPosition(newScroll);
  };

  const addScrollListenerCategory = () => {
    categoryWrapperRef.addEventListener('wheel', scrollListenerCategory);
  };

  const removeScrollListenerCategory = () => {
    categoryWrapperRef.removeEventListener('wheel', scrollListenerCategory);
  };
  

  useEffect(() => {
    if (!selectedCategory && selectedCategory !== 0) return;
    const activeHeaderElement = document.querySelector('.menu-nav-item.active');
    if (activeHeaderElement && categoryWrapperRef && !initialSelectCategory) {
      const elementRect = activeHeaderElement.getBoundingClientRect();
      const containerRect = categoryWrapperRef.getBoundingClientRect();
      let newScrollLeft =
        categoryWrapperRef.scrollLeft -
        scrollPadding +
        elementRect.left - containerRect.left;

      categoryWrapperRef.scrollTo({
        top: window.scrollY,
        left: newScrollLeft,
        behavior: 'auto',
      });
    }

    setInitialSelectCategory(false);
  }, [selectedCategory]);

  useEffect(() => {
    if (categoryWrapperRef && menuPosition !== 0) {
      categoryWrapperRef.scrollLeft = menuPosition;
    }
  }, [categoryWrapperRef]);

  useEffect(() => {
    if (!categoryWrapperRef) return;

    const isOverflowing = categoryWrapperRef.scrollWidth > categoryWrapperRef.clientWidth + spacerWidth;
    setAddSpacer(isOverflowing);

    // If overflowing, calculate spacer width
    if (isOverflowing && isTabletScreen) {
      const lastElement = categoryWrapperRef.children[categoryWrapperRef.children.length - 2]; // Get the second to last child
      if (lastElement) { // Ensure the element exists
        const lastElementWidth = lastElement.clientWidth;
        const calculatedSpacerWidth = categoryWrapperRef.clientWidth - lastElementWidth - (scrollPadding * 2);
        setSpacerWidth(calculatedSpacerWidth);
      }
    } else {
      setSpacerWidth(0);
    }
  }, [width, categoryWrapperRef, selectedCategory]);

  const handleOnClick = (category) => {
    scrollToCategory(category);
  };

  return (
    <div className={`container is-max-widescreen ${inApp ? 'in-app' : ''}`}
      style={isTabletScreen
        ? { zIndex: 300, position: 'fixed', bottom: (refs.current.bannerRef?.clientHeight ?? 0) }
        : { zIndex: 300, position: 'sticky', top: (refs.current.navRef?.clientHeight ?? 0) }}
      ref={r => refs.current.headerRef = r}
    >
      <div style={{ position: 'relative' }}>
        <div style={{ width: '100%', height: '100%' }} ref={r => refs.current.menuHeaderRef = r}>

          <section id='menu-categories' className={`menu-header ${!isTabletScreen ? 'section is-scroller' : ''}`}>
            <div
              className={'container px-0-mobile px-0-tablet'}
              style={{
                width: '100%',
                marginTop: '0.5rem', // required to avoid clash with Filter button
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                ref={setCategoryWrapperRef}
                className={`menu-nav-item-wrapper px-4-tablet-only scrollbar-slim ${isTabletScreen ? 'small-screen' : ''}`}
                onMouseEnter={addScrollListenerCategory}
                onMouseLeave={removeScrollListenerCategory}
                onScroll={e => {
                  categoryWrapperRef.scrollLeft = e.target.scrollLeft;
                  throttledSetMenuPosition(e.target.scrollLeft);
                }}
              >
                {menuCategories?.filter(mc => !excludedCategories.includes(mc.name)).map((category, idx) => (
                  <div
                    key={`category-${idx}`}
                    className={`menu-nav-item ${idx === selectedCategory ? 'active' : ''}`}
                    onClick={() => handleOnClick(idx)}
                  >
                    <h2 className='categoryHeaders'>
                      {category.name}
                    </h2>
                  </div>
                ))}
                {addSpacer && <div id={'categorySpacer'} style={{ minWidth: spacerWidth, height: '100%' }}>&nbsp;</div>}
              </div>
            </div>
          </section>
        </div>
        <MenuSubHeader
          subCategories={menuCategories[selectedCategory]?.subCategories ?? []}
          menuCategories={menuCategories}
          isLoadingCategories={isLoadingCategories}
          selectedSubCategory={selectedSubCategory}
          selectedCategory={selectedCategory}
          inApp={inApp}
          scrollToSubCategory={scrollToSubCategory}
          isTabletScreen={isTabletScreen}
          refs={refs}
        />
        <FilterMenuButton setDietModalIsOpen={setDietModalIsOpen} filters={filters} />
      </div>
    </div>
  );
};

export default MenuHeader;
