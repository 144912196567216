import { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useSelector } from 'react-redux';

const MenuSubHeader = ({ subCategories, selectedCategory, selectedSubCategory, inApp, scrollToSubCategory, isTabletScreen, refs }) => {
  const [subCategoryWrapper, setSubCategoryWrapper] = useState();
  const [spacerWidth, setSpacerWidth] = useState(0);
  const [addSpacer, setAddSpacer] = useState(false);
  const appInsets = useSelector(state => state.session.appInsets);
  const { width } = useWindowDimensions();
  const [initialSelectCategory, setInitialSelectCategory] = useState(true);
  const menuSubHeaderContainerRef = useRef(null);
  const scrollPadding = 20;

  const scrollListenerCategory = (e) => {
    e.preventDefault();
    const newScroll = subCategoryWrapper.scrollLeft + e.deltaY / 2;
    subCategoryWrapper.scrollLeft = newScroll;
  };

  const addScrollListenerCategory = () => {
    subCategoryWrapper.addEventListener('wheel', scrollListenerCategory);
  };

  const removeScrollListenerCategory = () => {
    subCategoryWrapper.removeEventListener('wheel', scrollListenerCategory);
  };

  useEffect(() => {
    if (!selectedSubCategory && selectedSubCategory !== 0) return;
    const activeSubHeaderElement = document.querySelector('.sub.menu-nav-item.active');
    if (activeSubHeaderElement && subCategoryWrapper && !initialSelectCategory) {
      const elementRect = activeSubHeaderElement.getBoundingClientRect();
      const containerRect = subCategoryWrapper.getBoundingClientRect();
      let newScrollLeft =
        subCategoryWrapper.scrollLeft -
        scrollPadding +
        elementRect.left - containerRect.left;

      subCategoryWrapper.scrollTo({
        top: window.scrollY,
        left: newScrollLeft,
        behavior: 'auto',
      });
    }
    setInitialSelectCategory(false);
  }, [selectedSubCategory]);

  useEffect(() => {
    if (!subCategoryWrapper) return;
  
    // Use getBoundingClientRect to calculate wrapper width
    const wrapperRect = subCategoryWrapper.getBoundingClientRect();
    const wrapperWidth = wrapperRect.width; // Visible width of the wrapper element
  
    // Calculate the total scrollable width using children
    const totalChildWidth = Array.from(subCategoryWrapper.children).reduce((acc, child) => {
      const childRect = child.getBoundingClientRect();
      return acc + childRect.width;
    }, 0);
  
    // Check for overflow
    const isOverflowing = totalChildWidth > wrapperWidth + spacerWidth;
    setAddSpacer(isTabletScreen || isOverflowing);
  
    // Spacer logic if overflowing or in tablet view
    if ((isTabletScreen) && subCategoryWrapper.children.length > 1) {
      const children = Array.from(subCategoryWrapper.children);
      const lastElement = children[children.length - 2]; // Get the second-to-last child
  
      if (lastElement) {
        const lastElementRect = lastElement.getBoundingClientRect();
        const lastElementWidth = lastElementRect.width;
  
        const calculatedSpacerWidth = wrapperWidth - lastElementWidth - (scrollPadding * 2);
        setSpacerWidth(calculatedSpacerWidth);
      }
    } else {
      setSpacerWidth(0);
    }
  }, [width, subCategoryWrapper, selectedSubCategory, selectedCategory]);

  const handleOnClick = (subCategory) => {
    scrollToSubCategory(subCategory);
  };

  const sortedSubCategories = [...subCategories].sort((a, b) => a.displayIndex - b.displayIndex);

  return (
    <>
      {
        <section id='menu-sub-categories'
          className={`menu-sub-header ${!isTabletScreen ? 'section is-scroller' : ''}`}
          style={{
            paddingBottom:
              isTabletScreen &&
              !refs.current.bannerRef?.clientHeight &&  // Doesn't have checkout banner below it
              (appInsets?.bottom ? appInsets.bottom : ''),
          }}
          >
          <div className={`container is-max-widescreen menu-sub-header-container ${inApp ? 'in-app' : ''}`}
            style={{
              width: '100%',
              display: 'flex',
              marginTop: !isTabletScreen ? '0.5rem' : '', // required to avoid clash with Filter button
              alignItems: 'center',
              justifyContent: `${!isTabletScreen ? 'center' : 'flex-start'}`
            }}
            ref={menuSubHeaderContainerRef}>
            <div
              ref={(ref) => setSubCategoryWrapper(ref)}
              className={`menu-nav-item-wrapper px-4-tablet-only scrollbar-slim ${isTabletScreen ? 'small-screen' : ''}`}
              onMouseEnter={addScrollListenerCategory}
              onMouseLeave={removeScrollListenerCategory}
              onScroll={e => {
                subCategoryWrapper.scrollLeft = e.target.scrollLeft;
              }}
            >
              {sortedSubCategories?.map((subCategory, idx) => (
                <div
                  key={subCategory.name}
                  className={`sub menu-nav-item ${idx === selectedSubCategory ? 'active' : ''}`}
                  onClick={() => handleOnClick(idx)}
                >
                  <h2 key={`sub-category-${idx}`}
                    className='subCategoryHeaders'
                    style={{
                      borderBottom: idx === selectedSubCategory && isTabletScreen
                        ? '2px solid #FFF'
                        : ''
                    }}>
                    {subCategory.name}
                  </h2>
                </div>
              ))}
              {addSpacer && <div id={'subCategorySpacer'} style={{ minWidth: spacerWidth, height: '100%' }}>&nbsp;</div>}
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default MenuSubHeader;
