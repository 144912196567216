import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UpsellModalCard from '../UpsellModalCard';
import Cross from '../shared/Cross';
import { getUniqueItems, applyDefaultSubProducts, getQuickAddProperties } from '../../helpers/basketHelpers';
import { freshRelevance, freshTrackClick, gtmEvent } from '../../helpers/commonHelpers';
import { useAddToBasketMutation } from '../../services/basket.api';
import hash from 'object-hash';

const UpsellModal = ({ onClose, screenWidth, navigateTo, upsell, isSuccess, refreshBasket, refetch, restaurantID }) => {
  const { restaurant, inApp } = useSelector(state => state.session);
  const [upsellItems, setUpsellItems] = useState([]);
  const isDelivery = useSelector(state => state.basket.isDelivery);
  const [currentDips, setCurrentDips] = useState([]);
  const dipsPresent = currentDips.some(d => d.quantity);
  const [addToBasket] = useAddToBasketMutation();
  const customer = useSelector(state => state.session.customer);
  const popupModalIds = useSelector(state => state.session.frontEndConfig?.popupModalIds);
  const { enableFreshRecommendation } = useSelector(state => state.session.features);
  
  const handleContinue = async (valuesChanged = false, dipsPresent = true) => {
    const params = { dipsPresent, channel: inApp ? 'App' : 'Web' };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }
    gtmEvent(isDelivery ? 'Delivery_Continue_From_Dip_Modal' : 'Collection_Continue_From_Dip_Modal', params);
    if (valuesChanged) {
      const body = currentDips.map((item) => {
        const itemWithDefaults = applyDefaultSubProducts(item);
        if (getQuickAddProperties(item).isCustomisable) {
          const { quantity, ...itemToHash } = itemWithDefaults;
          const customID = hash(itemToHash);
          return { ...itemWithDefaults, restaurantId: restaurant.id, customID, quantity };
        }
        return { ...itemWithDefaults, restaurantId: restaurant.id };
      });

      const response = await addToBasket(body);

      if (response?.data?.status === 'OK') {
        refreshBasket();
        refetch({ id: restaurantID, type: 'Upsell' });
        const url = `order.pizzaexpress.com/${isDelivery ? 'delivery' : 'collection'}`;
        currentDips.forEach((dip) => {
          if (enableFreshRecommendation && dip.meta && dip.quantity > 0 && Object.keys(dip.meta).length > 0) {
            freshTrackClick(dip.meta, url, dip.objectId);
          }
        });
        freshRelevance('pageChange', null, { 'sendBeacon': true });
        navigateTo('/basket');
      }
    } else {
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      onClose();
      navigateTo('/basket');
    }
  };

  const handleQuantityChange = (item, isAdding) => {

    setCurrentDips(prev => {
      const exists = prev?.find((dip) => dip.id === item.id);

      if (exists) {
        return ([...prev].map((dip) => {
          if (dip.id === item.id) {
            if (isAdding) {
              return ({ ...dip, quantity: dip.quantity + 1 });
            } else {
              return ({ ...dip, quantity: Math.max(dip.quantity - 1, 0) });
            }
          }
          return dip;
        }).filter(i => i.quantity > 0));

      } else if (!exists && isAdding) {
        return ([...prev, { ...item, quantity: 1 }]);
      } else if (!exists && !isAdding) {
        return prev;
      }
    });
  };

  const checkQuantityChanged = () => {
    const changed = currentDips.some(dip => dip?.quantity > 0);

    return changed;
  };

  useEffect(() => {
    if (isSuccess && upsell) {
      if (upsell.dips.length === 0) handleContinue(false, false);
      setUpsellItems(getUniqueItems(upsell.dips));
    }
  }, [upsell]);

  return (
    <div
      className='upsell-modal'
      style={inApp ? { textAlign: 'center' } : {}}
    >
      <div className='header-wrapper'>
        <div className='header-title-container'>
          <h1 className={`title`}>
            ANYTHING EXTRA?
          </h1>
          <p style={{ fontSize: 14 }}>We recommend these popular choices:</p>
        </div>
        {
          screenWidth > 550 &&
          <Cross onClick={onClose} />
        }
      </div>
      <div style={{
        justifyContent: 'center',
        gap: '1rem',
        display: 'flex',
      }}>
        {
          upsellItems
            .filter(item => !item?.outOfStock && getQuickAddProperties(item, popupModalIds).isAddable).slice(0, 2).map((item, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: inApp ? '#fff' : '',
                  display: 'flex',
                  alignItems: 'stretch',
                  alignContent: 'stretch',
                  flexDirection: 'column',
                  width: '148px',
                  borderRadius: inApp ? '0.5rem' : '',
                }}
              >
                <UpsellModalCard screenWidth={screenWidth}
                  item={item}
                  quantity={(currentDips?.find(dip => dip.id === item.id))?.quantity ?? 0}
                  id={'upsell-' + i}
                  inApp={inApp}
                  handleQuantityChange={handleQuantityChange}
                />
              </div>
            ))
        }
      </div>
      <div className="upsell-modal-cont-button-wrapper">
      <button id='confirm' className='btn btn-primary' onClick={() => handleContinue(checkQuantityChanged(), dipsPresent)}>
        {`${checkQuantityChanged() ? 'Add' : 'Continue'} to basket`}
        </button>
      </div>
    </div >
  );
};

export default UpsellModal;
