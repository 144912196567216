import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../../src/store/storeTypes';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CustomiseHeader from '../components/CustomiseHeader';
import CustomiseSection from '../components/CustomiseSection';
import {
  calcSubTotal,
  addParentReferences,
  dereffItems,
  applyDefaultSubProducts,
  useQuickAddProperties} from '../../../helpers/basketHelpers';
import { customItemOptionsToGtmParams } from '../../../helpers/gtmEventHelpers';
import hash from 'object-hash';
import { useGetSingleMenuItemQuery } from '../../../services/restaurant.api';
import { historyMW } from '../../../helpers/routingHelpers';
import PopupBannerController from '../../../components/PopupBannerController';
import { useAddToBasketMutation, useUpdateBasketMutation } from '../../../services/basket.api';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { useConfig } from '../../../helpers/useConfig';
import chevronLeft from '../../../img/icons/ChevronLeft24.svg';
import minimize from '../../../img/icons/minus-outlined-white.svg';
import plus from '../../../img/icons/plus-outlined-white.svg';
import { Tags } from '../../../components/Tags';
import Footer from '../../../components/Footer';
import Loading from '../../../components/Loading';

const dietIcon = ['hot', 'gluten free', 'vegan', 'vegetarian'];

const getCustomItem = (item, customId, basketItems, baseSelections) => {
  if (customId) {
    const basketItem = basketItems.find(el => el.customID === customId);
    if (basketItem) {
      return (dereffItems(basketItem));
    }
  }
  else if (item) {
    const baseSelectionOverride = baseSelections.find(b => b.product === item.id);

    if (baseSelectionOverride) {
      item.subProducts[0].defaultSubProduct = baseSelectionOverride.base;
    } else if(item.subProducts[0].defaultSubProduct === '111' && item.subProducts[0].subProducts.some(sp => sp.id === '26')) item.subProducts[0].defaultSubProduct = '26';

    const defaultItem = applyDefaultSubProducts(item);
    const dereffedItem = dereffItems(defaultItem);

    return ({ ...dereffedItem, customID: customId });
  }
}

export const Customise = ({ refs, restaurantId, refreshBasket, setNotification }) => {
  const config = useConfig();
  const basketItems = useSelector(state => state.basket.items);
  const basket = useSelector(state => state.basket);
  const baseSelections = useSelector(state => state.menu.baseSelections);
  const outOfStockItems = useSelector(state => state.menu.outOfStockItems);
  const isDelivery = useSelector(state => state.basket.isDelivery);
  const inApp = useSelector(state => state.session.inApp);
  const showCalories = useAppSelector(state => state.menu.showCalories);
  const popupModalIds = useSelector(state => state.session.frontEndConfig?.popupModalIds);
  const customer = useSelector(state => state.customer);
  const { itemId } = useParams();
  const { width: screenWidth } = useWindowDimensions();
  const navigate = useNavigate();
  const [invalidSections, setInvalidSections] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const { items, bundles, numberOfItems } = useSelector(state => state.basket);
  const [addToBasket] = useAddToBasketMutation();
  const [updateBasket] = useUpdateBasketMutation();
  const duoRomanaIds = useSelector(state => state.session.frontEndConfig?.duoRomanaIds);
  const pageRef = useRef(null);
  const currentTier = useSelector(state => state.session?.customer?.loyalty?.customer?.currentTier?.name);
  const loyaltySchemeVersion = useAppSelector(state => state.session?.customer?.loyalty?.customer?.loyaltySchemeVersion);
  const isTabletScreen = useSelector(state => state.session.isTabletScreen);
  const customiseBannerRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const { state } = useLocation();
  const [params] = useSearchParams();
  const { customId } = Object.fromEntries(params);
  const { data: menuItem, isLoading, isFetching } = useGetSingleMenuItemQuery({ id: restaurantId, itemId }, { skip: !restaurantId || state.menuItem });
  const [item, setItem] = useState(addParentReferences(state?.menuItem));
  const [customisedObj, setCustomisedObj] = useState(getCustomItem(item, customId, basketItems, baseSelections));
  const isDuoRomana = duoRomanaIds?.includes(item?.id);
  const [quantity, setQuantity] = useState(customisedObj?.quantity <= 0 ? 1 : customisedObj?.quantity);
  const { isCustomisable, isPizza } = useQuickAddProperties(item);

  useEffect(() => {
    if ((!isLoading || menuItem) && !state?.menuItem) {
      if (menuItem) {
        const itemWithParents = addParentReferences(menuItem);
        setItem(itemWithParents);
      } else if (restaurantId) {
        historyMW.push(`/menu/${restaurantId}`, isDelivery, navigate);
      }
    }
  }, [menuItem, isLoading, customId, state?.menuItem]);

  const customisePageRef = useRef();

  const addItemToBasket = async () => {
    const sections = Array.from(document.getElementsByClassName('customise-section'));
    const firstInvalidSectionNode = sections.filter(el => invalidSections.find(s => el.dataset.sectionErrorId === s))[0];
    if (invalidSections.length && firstInvalidSectionNode) {
      scrollToInvalidSection(firstInvalidSectionNode);
      setShowErrors(true);
    } else if (customId) {
      await updateBasket({
        restaurantId,
        numberOfItems,
        items: items.map(item => item.customID === customId ? { ...customisedObj, quantity, customID: hash(customisedObj) } : item),
        bundles
      });
      refreshBasket();
      setNotification(true);
      historyMW.push('/basket', isDelivery, navigate);
    } else {
      await addToBasket([{ ...customisedObj, calculatedCost: currentPrice * quantity, quantity, customID: hash(customisedObj), restaurantId }]);
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      refreshBasket();

      const event = {
        channel: inApp ? 'App' : 'Web',
        currency: config?.BRAND === 'PE' ? 'GBP' : 'EUR',
        value: basket.total,
        clickAndCollectEvent: isDelivery
          ? 'Delivery_Product_Details_Clicked'
          : 'Collection_Product_Details_Clicked',
      };

      const item = {
        item_id: customisedObj.id,
        item_name: customisedObj.name,
        price: customisedObj.calcSubTotal(),
        quantity: quantity,
        item_brand: config?.BRAND,
        item_category: customisedObj.categoryName
      };

      if (customer?.customerSessionToken?.pizzaExpressId) {
        event.customerId = customer.customerSessionToken.pizzaExpressId;
      }

      customItemOptionsToGtmParams(customisedObj, item);

      event['items'] = [item];
      gtmEvent('add_to_cart', event);

      setNotification(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (customId && item && !customisedObj) {
      historyMW.push(`/menu/${restaurantId}`, isDelivery, navigate);
    }
    else if (item && item?.id !== customisedObj?.id) {
      resetItem();
    }
  }, [item, customisedObj, params]);

  const resetItem = () => {
    const baseSelectionOverride = baseSelections.find(b => b.product === item.id);

    if (baseSelectionOverride) {
      item.subProducts[0].defaultSubProduct = baseSelectionOverride.base;
    } else if(item.subProducts[0].defaultSubProduct === "111" && item.subProducts[0].subProducts.some(sp => sp.id === '26')) item.subProducts[0].defaultSubProduct = "26";

    const defaultItem = applyDefaultSubProducts(item);
    const dereffedItem = dereffItems(defaultItem);

    setCustomisedObj({ ...dereffedItem, customID: customId });

    // Reset all selected options and only keep the base if it was selected from the base dropdown
    const lowerCaseName = item.name.toLowerCase().trim()
    setSelectedOptions(baseSelectionOverride ? { [lowerCaseName]: selectedOptions[lowerCaseName] } : {});
    setShowErrors(false);
  };

  const handleRequiredScrolling = (customisedObjId) => {
    const sections = Array.from(document.getElementsByClassName('customise-section'));
    const firstInvalidSectionNode = sections.filter(el => invalidSections.find(s => el.dataset.sectionErrorId === s) && el.dataset.sectionErrorId !== customisedObjId)[0];
    if (invalidSections.length && firstInvalidSectionNode) {
      scrollToInvalidSection(firstInvalidSectionNode);
    }
  }

  const scrollToInvalidSection = (invalidSection) => {
    const offsetHeight =
      refs.current.navRef?.clientHeight +
      customiseBannerRef?.current?.clientHeight +
      (!isTabletScreen ? 40 : 0);

    const modalScrollContainer = customisePageRef?.current;
    const currentScrollY = modalScrollContainer?.scrollTop || 0;

    const scrollOptions = {
      top: currentScrollY + invalidSection.getBoundingClientRect().top - offsetHeight,
      behavior: 'smooth',
      block: 'center'
    };
    modalScrollContainer?.scrollTo(scrollOptions);
  };

  const handleSelectedOptionsChange = (sectionId, options) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [sectionId]: options,
    }));
  };

  useEffect(() => {
    if (customisedObj) {
      customisedObj.calcSubTotal && setCurrentPrice(customisedObj.calcSubTotal());
    }
  }, [customisedObj]);

  const navHeight = refs.current.navRef?.offsetHeight ?? 0;

  if (isLoading || isFetching || !item || !customisedObj) {
    return (
      <div
        style={{
          width: '100vw',
          position: 'fixed',
          top: navHeight ?? 0,
          left: 0,
          bottom: 0,
          zIndex: 10000,
          backgroundColor: '#eae7e4',
          overflow: 'auto'
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div
      ref={customisePageRef}
      style={{
        width: '100vw',
        position: 'fixed',
        top: navHeight ?? 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: '#eae7e4',
        overflow: 'auto'
      }}
    >
      <div className='customise-banner' style={{ top: 0 }} ref={customiseBannerRef}>
        {!inApp ? (
          <button className={'btn-customise-back'} onClick={() => navigate(-1)}>
            <img src={chevronLeft} alt="Chevron" />
            Back to {customId ? 'basket' : 'menu'}
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div className='customise-container'
          ref={pageRef}
          style={inApp ? { paddingBottom: `${refs.current.bannerRef?.clientHeight + (!isTabletScreen ? 24 : 0)}px`, minHeight: `calc(100vh - ${refs.current.bannerRef?.clientHeight ?? 0}px - ${refs.current.navRef?.clientHeight ?? 0}px)` } : {}}>
          {item &&
            <div className='customise-product-info-container' style={isTabletScreen ? {} : { top: (customiseBannerRef?.current?.clientHeight ?? 0), position: 'sticky' }}>
              <img className='customise-product-image' src={item?.imageUrl} alt='food item' />
              <div className='customise-product-info'>
                <Tags
                  item={item}
                  currentTier={currentTier}
                  loyaltySchemeVersion={loyaltySchemeVersion}
                  showLocked={false}
                  excludeChildTags={isCustomisable}
                  wrapperClass={'all-tags-wrapper '}
                />
                <CustomiseHeader
                  dietIcon={dietIcon}
                  name={item?.name}
                  description={item?.description}
                  restaurantId={restaurantId}
                  screenWidth={screenWidth}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  customisedItem={customisedObj}
                  setShowErrors={setShowErrors}
                  invalidSections={invalidSections}
                  edit={!!customId}
                  refreshBasket={refreshBasket}
                  currentPrice={currentPrice}
                  addToBasket={addItemToBasket}
                  inApp={inApp}
                  item={item}
                  showCalories={showCalories}
                />
              </div>
            </div>
          }
          <div className='customise-card'>
            {item && !item.outOfStock &&
              item?.subProducts?.map((el, idx) => (
                <CustomiseSection
                  key={idx}
                  item={{ ...el, calcSubTotal }}
                  NewComponent={CustomiseSection}
                  result={customisedObj}
                  setCustomisedObj={setCustomisedObj}
                  invalidSections={invalidSections}
                  setInvalidSections={setInvalidSections}
                  showErrors={showErrors}
                  resetItem={resetItem}
                  outOfStockItems={outOfStockItems}
                  isDuoRomana={isDuoRomana}
                  duoRomanaIds={duoRomanaIds}
                  handleRequiredScrolling={handleRequiredScrolling}
                  currentTier={currentTier}
                  loyaltySchemeVersion={loyaltySchemeVersion}
                  showCalories={showCalories}
                  customId={customId}
                  selectedOptions={selectedOptions}
                  onSelectedOptionsChange={(options, id) => handleSelectedOptionsChange(id, options)}
                  isPizza={isPizza}
                  quantity={quantity}
                />
              ))
            }
          </div>
        </div>
        <div ref={r => refs.current.customiseFooterSpacer = r} style={{ width: '100%' }} />
        <PopupBannerController refs={refs} customise justifyEnd>
          <div className='customise-popup-container'>
            <div className='customise-card-amount-container'>
              {
                <>
                  <img
                    src={minimize}
                    className="icon is-medium"
                    role='icon'
                    style={{ cursor: quantity > 1 && !item.outOfStock? 'pointer' : 'none', opacity: quantity > 1 && !item?.outOfStock ? '1' : '0.2' }}
                    aria-disabled={item?.outOfStock}
                    onClick={() => !item?.outOfStock && setQuantity(prev => prev > 1 ? prev - 1 : prev)}
                  />
                  <p className="customise-card-amount-quantity" style={{ color: 'white' }}>{quantity}</p>
                  <img
                    src={plus}
                    className="icon is-medium"
                    role='icon'
                    style={{ cursor: !item?.outOfStock ? 'pointer' : 'none', opacity: item?.outOfStock ? '0.2' : '1' }}
                    aria-disabled={item?.outOfStock}
                    onClick={() => !item?.outOfStock && setQuantity(prev => prev + 1)}
                  />
                </>
              }
            </div>
            <button className={`checkout-btn btn btn-primary`} disabled={item?.outOfStock} style={{ width: 'min-content' }} onClick={addItemToBasket}>
              Add to basket - {config?.BRAND === 'PE' ? '£' : '€'}{(currentPrice * quantity).toFixed(2)}
            </button>
          </div>
        </PopupBannerController>
      </div>
      {!inApp && <Footer refs={refs} inApp={inApp} spacerRefName={'customiseFooterSpacer'} refName={'customiseFooter'} />}
    </div>
  );
};

export default Customise;