import React, { useEffect, useState, useRef } from 'react';
import { useConfig } from '../../helpers/useConfig';
import LoaderBlack from '../../img/common/Loader_360x360.gif';

const calculateClassesToAdd = (empty, lastItem) => {
  if (empty) return 'card-no-order';
  return lastItem ? 'card2' : 'card1';
};

const LoyaltyTicket = ({ sectionIndex, onTicketClick, offer, isDisabled, lastItem, empty, keyValue, tierName, inApp }) => {
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const innerCard = useRef(null);
  const config = useConfig();

  useEffect(() => {
    if (offer?.selectedByThisUser !== selected) {
      setSelected(offer?.selectedByThisUser);
    }
  }, [offer?.selectedByThisUser]);
  
  const handleTicketClick = async (offer, index) => {
    if (isDisabled) return;
    
    if (selected)
      setSelected(false);
    
    
    if (onTicketClick) {
      setLoading(true);
      await onTicketClick(offer, index, sectionIndex);
      setLoading(false);
    }
  };
  
  return (
    <>
      {!!offer && <div 
        className="card-parent is-relative" 
      >
        <div 
          ref={innerCard} 
          id="div4-id" 
          className={`voucher-card ticket-container ${calculateClassesToAdd(empty, lastItem)} ${selected ? ' selected ' : ''}`}
          onClick={() => offer?.disabled ? null : handleTicketClick(offer, keyValue)}>
          <div className="ticket-container-inner">
            <div className={`ticket-container-inner-layout ${selected && 'selected'} ${tierName.toLowerCase()}`}>
              <div className="card-content" style={{width: '100%'}}>
                <div 
                  className={`title ${(offer?.disabled || isDisabled) ? 'is-disabled' : ''}`}
                  style={{fontSize: inApp && 18}}
                >
                {offer?.title}</div>
                {offer?.subTitle && <div className="subTitle">{offer?.subTitle}</div>}
                {offer?.romanaUpgradePrice && <div className="romanaUpgrade">+ {config.CURRENCY_SYMBOL}{offer?.romanaUpgradePrice.toFixed(2)} Romana Upgrade</div>}
                {offer?.showPrice === true && <div className={`amount ${(offer?.disabled || isDisabled) ? 'is-disabled' : ''}`} style={{color: (offer?.disabled || isDisabled) ? 'rgba(28,26,26,0.5)' : '' }}>Save: {config.CURRENCY_SYMBOL}{offer?.price.toFixed(2)}</div>}
                { (offer.selected && !offer.selectedByThisUser) && <div className="item-taken" style={{color: '#016BAD', fontSize: 12}}>This product has been taken.</div>}
              </div>
              <div className={`${loading ? 'hole-position' : 'hole'} ${(offer?.disabled || isDisabled) ? 'is-disabled' : ''}`} >
                {loading && <img src={LoaderBlack} alt="logo" />}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>);
};

export default LoyaltyTicket;
