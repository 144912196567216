import { createSlice } from '@reduxjs/toolkit';
import { applicationApi } from '../services/application.api';
import { customerApi } from '../services/customer.api';
import { ExtendedMenuState } from '../types/MenuTypes';
import { menuApi } from '../services/menu.api';
import { getAvailableCategories } from '../helpers/menuHelpers';

interface LocalMenuState extends ExtendedMenuState {
  filters: string[],
  showCalories: boolean,
  activeCategory: number,
  activeCategoryName: string,
  notification: string,
  baseSelections: {
    product: string,
    base: string
  }[],
  excludedCategories: string[]
}

const initialState = {
  restaurantId: 0,
  categories: [],
  bundles: [],
  filters: [],
  showCalories: true,
  activeCategory: 0,
  activeCategoryName: '',
  notification: '',
  baseSelections: [],
  excludedCategories: [],
  hash: ''
} as LocalMenuState;

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateFilters(state, { payload }) {
      state.filters = payload;
      const { availableCats, excludedSubCats } = getAvailableCategories(state.categories, state.bundles, state.filters);
      state.categories = availableCats;
      state.excludedCategories = excludedSubCats;
    },
    setShowCalories(state, { payload }) {
      state.showCalories = payload;
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
      state.activeCategoryName = state.categories.at(payload)?.name ?? '';
    },
    setBaseSelections(state, { payload }) {
      state.baseSelections = payload;
    },
    clearBundles(state) {
      state.bundles = [];
    },
    resetMenuState(state) {
      state = {...initialState};
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      applicationApi.endpoints.getApplicationState.matchFulfilled,
      (state, { payload }) => {
        if (payload.menu) {
          const { availableCats, excludedSubCats } = getAvailableCategories(payload.menu.categories, payload.menu.bundles, state.filters);
          state.hash = payload.menu.hash;
          state.restaurantId = payload.menu.restaurantId;
          state.categories = availableCats;
          state.excludedCategories = excludedSubCats;
          state.bundles = payload.menu.bundles;
        }
      }
    );
    builder.addMatcher(
      menuApi.endpoints.getMenuState.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          const { availableCats, excludedSubCats } = getAvailableCategories(payload.categories, payload.bundles, state.filters);
          state.hash = payload.hash;
          state.restaurantId = payload.restaurantId;
          state.categories = availableCats;
          state.excludedCategories = excludedSubCats;
          state.bundles = payload.bundles;
      }
    });
    builder.addMatcher(
      customerApi.endpoints.logout.matchFulfilled,
      () => {
        return initialState;
      }
    );
  }
});

export const { clearBundles, resetMenuState, updateFilters, setShowCalories, setActiveCategory, setBaseSelections } = menuSlice.actions;
export default menuSlice.reducer;